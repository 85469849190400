import React, { useState, useEffect, Suspense, lazy } from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import logic from '../logic'
import {
    setLogoAndIdentity,
    setFilters,
    setMenu,
    setOffice,
    setProjects,
    setShop, 
    setLinks,
    setSelectedMenu,
    setSlider,
    setSelectedFilter,
    setAwards
} from '../actions'
import {
    faAngleDown, faAngleLeft, faAngleRight, faAngleUp, faCalendarAlt, faCalendarDay, faDoorClosed, faDoorOpen,
    faEdit, faLink, faEraser, faFileAlt, faGripLines, faInfoCircle, faLocationArrow, faLock, faPlus, faSave,
    faSearch, faSignOutAlt, faTrash, faTrashAlt, faUnlockAlt, faUser, faGlobeEurope, faTimes, faLockOpen, faSpinner,
    faAsterisk, faLongArrowAltLeft, faLongArrowAltRight, faUndoAlt, faChevronCircleUp, faChevronCircleDown, faCog, faEyeDropper,
} from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import { isMobileOnly } from 'react-device-detect'
import './App.css'
import isAuthenticatedUserMiddleware from '../middlewares/isAuthenticatedUserMiddleware'

library.add(faUser, faUnlockAlt, faFileAlt, faLock, faLink, faEdit, faSignOutAlt, faCalendarAlt, faSearch, faAngleRight,
    faAngleLeft, faEraser, faAngleDown, faAngleUp, faCalendarDay, faInfoCircle, faGripLines, faSave, faPlus, faTrash,
    faTrashAlt, faLocationArrow, faEdit, faDoorOpen, faDoorClosed, faGlobeEurope, faTimes, faLockOpen, faSpinner,faAsterisk,
    faLongArrowAltLeft, faLongArrowAltRight,faUndoAlt, faChevronCircleUp, faChevronCircleDown, faCog, faEyeDropper)

const Home = lazy(() => import('./Home'))
const Office = lazy(() => import('./Office')) 
const Contact = lazy(() => import('./Contact')) 
const ProjectDetail = lazy(() => import('./ProjectDetail')) 
const Projects = lazy(() => import('./Projects')) 
const Shop = lazy(() => import('./Shop'))
const Login = lazy(() => import('./Login')) 
const AdministrationHome = lazy(() => import('./Administration/AdministrationHome')) 

const App = (props) => {

    const [loading, setLoading] = useState(false)
    const [color] = useState('white')

    useEffect(() => {
        (async () => {
            await getAllTheWebResources()
        })()
    }, [])

    const getAllTheWebResources = async () => {
        try {
            const data = await logic.getInformation()
            const slider = await logic.getSliderInformation()
            const projects = await logic.getProjectsInformation()
            props.dispatchSetProjects(projects)   
            props.dispatchSetLogoAndIdentity(data[0].logo+''+data[0].identity)
            props.dispatchSetFilters(data[0].filters)
            props.dispatchSetMenu(data[0].menu)
            props.dispatchSetOffice(data[0].office)
            props.dispatchSetLinks(data[0].links)
            console.log(data[0].links)
            props.dispatchSetAwards(data[0].awards.reverse())
            props.dispatchSetSelectedMenu('Home')      
            props.dispatchSetSlider(slider)
            props.dispatchSetSelectedFilter('All')
            setLoading(true)
        } catch(e) {
            console.error(e.message)
        }
    }

    if(loading)
        if(!isMobileOnly)
            return (
                <div className="AppMain" style={{backgroundColor:`${color}`}}>
                    <Suspense fallback="">
                        <BrowserRouter>
                            <Switch>
                                <Route exact path='/' component={Home} />
                                <Route exact path='/projects' component={Projects} />
                                <Route path='/projects/:name' component={ProjectDetail} />
                                <Route exact path='/office' component={Office} />
                                {/* <Route exact path='/contact' component={Contact} /> */}
                                {/* <Route exact path='/administration' component={Login}/> */}
                                {/* <Route exact path='/administration/home' component={AdministrationHome}/> */}
                                {/* <Route exact path='/administration/landing' component={isAuthenticatedUserMiddleware(LandingProject)}/> */}
                                {/* <Route exact path='/admin/landing/project/:id' component={LandingProject}/> */}
                            </Switch>
                        </BrowserRouter>
                    </Suspense>
                </div>
            )
        else 
        return (
            <div className="AppMain" style={{backgroundColor:`${color}`}}>
                <Suspense fallback="">
                    <BrowserRouter>
                        <Switch>
                            <Route exact path='/' render={() => <Redirect to='/projects'/>} />
                            <Route exact path='/projects' component={Projects} />
                            <Route path='/projects/:name' component={ProjectDetail} />
                            <Route exact path='/office' component={Office} />
                            {/* <Route exact path='/contact' component={Contact} /> */}
                            {/* <Route exact path='/admin' component={Login}/>
                            <Route exact path='/admin/landing' component={Landing}/>
                            <Route exact path='/admin/landing/project/:id' component={LandingProject}/> */}
                        </Switch>
                    </BrowserRouter>
                </Suspense>
            </div>
        )
    else return null
}

const mapStateToProps = state => ({
    selectedMenu: state.selectedMenu,
    office: state.office,
    device: state.device
})

const mapDispatchToProps = dispatch => (
    {
        dispatchSetLogoAndIdentity: value => dispatch(setLogoAndIdentity(value)),
        dispatchSetFilters: value => dispatch(setFilters(value)),
        dispatchSetMenu: value => dispatch(setMenu(value)),
        dispatchSetOffice: value => dispatch(setOffice(value)),
        dispatchSetProjects: value => dispatch(setProjects(value)),
        dispatchSetShop: value => dispatch(setShop(value)),
        dispatchSetLinks: value => dispatch(setLinks(value)),
        dispatchSetSelectedMenu: value => dispatch(setSelectedMenu(value)),
        dispatchSetSlider: value => dispatch(setSlider(value)),
        dispatchSetSelectedFilter: value => dispatch(setSelectedFilter(value)),
        dispatchSetAwards: value => dispatch(setAwards(value))
    }
)

export default connect(mapStateToProps, mapDispatchToProps)(App)