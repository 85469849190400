
export const SET_LOGO_AND_IDENTITY = 'SET_LOGO_AND_IDENTITY',
SET_MENU = 'SET_MENU',
SET_FILTERS = 'SET_FILTERS',
SET_OFFICE = 'SET_OFFICE',
SET_PROJECTS = 'SET_PROJECTS',
SET_SHOP = 'SET_SHOP',
SET_LINKS = 'SET_LINKS',
SET_SELECTED_MENU = 'SET_SELECTED_MENU',
SET_SLIDER = 'SET_SLIDER',
SET_SELECTED_FILTER = 'SET_SELECTED_FILTER',
SET_DEVICE = 'SET_DEVICE',
SET_AWARDS = 'SET_AWARDS',
SET_ISANIMATION = 'SET_ISANIMATION'

export const setLogoAndIdentity = payload => ({ type: SET_LOGO_AND_IDENTITY, payload }),
setMenu = payload => ({type: SET_MENU, payload }),
setFilters = payload => ({type: SET_FILTERS, payload }),
setOffice = payload => ({ type: SET_OFFICE, payload }),
setProjects = payload => ({ type: SET_PROJECTS, payload }),
setShop = payload => ({ type: SET_SHOP, payload }),
setLinks = payload => ({ type: SET_LINKS, payload }),
setSelectedMenu = payload => ({ type: SET_SELECTED_MENU, payload }),
setSlider = payload => ({ type: SET_SLIDER, payload }),
setSelectedFilter = payload => ({ type: SET_SELECTED_FILTER, payload }),
setDevice = payload => ({ type: SET_DEVICE, payload }),
setAwards = payload => ({ type: SET_AWARDS, payload }),
setIsAnimation = payload => ({ type: SET_ISANIMATION, payload })