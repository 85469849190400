import axios from 'axios'
import config from '../config'
import storeToken from '../store/token'

export default {

    getBaseUrl: function () {
        return `${config.url.RequenaProd}`
    },

    getHeaders: function () {
        return {
            headers: {
                'Content-type': 'application/json; charset=utf-8'
            }
        }
    },

    setToken: function (token) {
        storeToken.setToken('token', token)
        return true
    },

    authenticate(username, password) {

        const body = {
            username,
            password
        }

        return axios.post(`${this.getBaseUrl()}/authenticate`, body, this.getHeaders())
            .then(response => {

                const { status, data } = response

                if (status === 200) {
                    const { token } = data
                    this.setToken(token)
                    return data
                }

            })

    },

    // Lógica para traer la información principal de la web

    getInformation(){
        return axios.get(`${this.getBaseUrl()}/information`, this.getHeaders)
            .then(response => {
                if (response.status === 200){
                    const {data: {data}} = response
                    return data
                }
            })
    },

    // Lógica para traer la información general de todos los proyectos para la página de proyectos

    getProjectsInformation(){
        return axios.get(`${this.getBaseUrl()}/projects`, this.getHeaders)
            .then(response => {
                if(response.status === 200) {
                    const {data: {data}} = response
                    return data
                }
            })
    },

    // Lógica para traer la información de un proyecto concreto para la página individual del proyecto
    
    getProject(name){
        return axios.get(`${this.getBaseUrl()}/project/${name}`, this.getHeaders)
            .then(response => {
                if (response.status === 200){
                    const {data: {data}} = response
                    return data
                }
            })
    },

    // Lógica para traer la información del slider: las imágenes en orden y sus respectivos links.

    getSliderInformation(){
        return axios.get(`${this.getBaseUrl()}/slider`, this.getHeaders)
            .then(response => {
                if(response.status === 200) {
                    const {data: {data}} = response
                    return data
                }
            })
    },

    // Administration => Slider functions

    getSliderProjectsToAdd(){
        return axios.get(`${this.getBaseUrl()}/slider/projectstoadd`, this.getHeaders)
            .then(response => {
                if (response.status === 200){
                    const {data: {data}} = response
                    return data
                }
            })
    },
    
    addNewProjectToSlider(id){
        return axios.post(`${this.getBaseUrl()}/slider/addprojecttoslider/${id}`, this.getHeaders)
            .then(response => {
                if (response.status === 200){
                    const {data: {data}} = response
                    return data
                }
            })
    },

    removeProjectFromSlider(id){
        return axios.post(`${this.getBaseUrl()}/slider/removeprojectfromslider/${id}`, this.getHeaders)
            .then(response => {
                if (response.status === 200){
                    const {data: {data}} = response
                    return data
                }
            })
    },

    changeProjectPositionFromSlider(id, action) {
        return axios.post(`${this.getBaseUrl()}/slider/changeprojectpositionfromslider/${id}`, {action}, this.getHeaders)
            .then(response => {
                if (response.status === 200){
                    const {data: {data}} = response
                    return data
                }
            })
    },

    // Administration => Project functions

    saveProjectCardInformation(id, title, subtitle, color) {
        return axios.post(`${this.getBaseUrl()}/project/${id}/cardinformation`, {title, subtitle, color}, this.getHeaders)
            .then(response => {
                if (response.status === 200){
                    const {data: {data}} = response
                    return data
                }
            })
    },

    changeProjectPosition(id, action) {
        return axios.post(`${this.getBaseUrl()}/project/changeprojectposition/${id}`, {action}, this.getHeaders)
            .then(response => {
                if (response.status === 200){
                    const {data: {data}} = response
                    return data
                }
            })
    },

    addNewComponentProject(id, componentName, componentSelect, componentSelect01, componentSelect02, projectName, description, credits, tags, url, fileName) {
        let body = {}
        body.name = componentName
        switch (componentName) {
            case 'Main Image':
                body.select = componentSelect
                break
            case 'Full Image':
                body.select = componentSelect
                break
            case 'Landscape photo gallery':
                body.select01 = componentSelect01
                body.select02 = componentSelect02
                break
            case 'Portrait photo gallery' :
                body.select01 = componentSelect01
                body.select02 = componentSelect02
                break
            case 'Project description':
                body.projectName=projectName
                body.description=description
                break
            case 'Credits':
                body.credits = credits
                body.tags = tags
                break
            case 'Video de vimeo':
                body.url = url
                break
            case 'Video animation':
                body.fileName = fileName
                break
            default:
                break
        }
        return axios.post(`${this.getBaseUrl()}/project/${id}/addprojectcomponent`, body, this.getHeaders)
            .then(response => {
                if (response.status === 200){
                    const {data: {data}} = response
                    return data
                }
            })
    },

    updateComponent(id, position, componentName, componentSelect, componentSelect01, componentSelect02, projectName, description, credits, tags, url, fileName){
        let body = {}
        body.name = componentName
        body.position = position
        switch (componentName) {
            case 'Main Image':
                body.select = componentSelect
                break
            case 'Full Image':
                body.select = componentSelect
                break
            case 'Landscape photo gallery':
                body.select01 = componentSelect01
                body.select02 = componentSelect02
                break
            case 'Portrait photo gallery' :
                body.select01 = componentSelect01
                body.select02 = componentSelect02
                break
            case 'Project description':
                body.projectName=projectName
                body.description=description
                break
            case 'Credits':
                body.credits = credits
                body.tags = tags
                break
            case 'Video de vimeo':
                body.url = url
                break
            case 'Video animation':
                body.fileName = fileName
                break
            default:
                break
        }

        return axios.post(`${this.getBaseUrl()}/project/${id}/updatecomponent`, body, this.getHeaders)
            .then(response => {
                if (response.status === 200){
                    const {data: {data}} = response
                    return data
                }
            })
    },

    changeProjectComponentPosition(id, name, position, action) {
        let body = {}
        body.componentName = name
        body.componentPosition = position
        body.componentAction = action
        return axios.post(`${this.getBaseUrl()}/project/${id}/changecomponentposition`, body, this.getHeaders)
            .then(response => {
                if (response.status === 200){
                    const {data: {data}} = response
                    return data
                }
            })
    },

    removeProjectComponent(id, name, position) {
        return axios.post(`${this.getBaseUrl()}/project/${id}/removeprojectcomponent`, {name, position}, this.getHeaders)
            .then(response => {
                if (response.status === 200){
                    const {data: {data}} = response
                    return data
                }
            })
    }
}