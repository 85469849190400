import {
    SET_LOGO_AND_IDENTITY,
    SET_FILTERS,
    SET_MENU,
    SET_OFFICE,
    SET_PROJECTS,
    SET_SHOP,
    SET_LINKS,
    SET_SELECTED_MENU,
    SET_SLIDER,
    SET_SELECTED_FILTER,
    SET_DEVICE,
    SET_AWARDS,
    SET_ISANIMATION
} from '../actions'

export const informationReducer = (state = {}, action) => {
    switch (action.type) {
        case SET_LOGO_AND_IDENTITY:
            return {...state, logoAndIdentity: action.payload}
        case SET_FILTERS:
            return {...state, filters: action.payload}
        case SET_MENU:
            return {...state, menu: action.payload}
        case SET_OFFICE:
            return {...state, office: action.payload}
        case SET_PROJECTS:
            return {...state, projects: action.payload}
        case SET_SHOP:
            return {...state, shop: action.payload}
        case SET_LINKS:
            return {...state, links: action.payload}
        case SET_SELECTED_MENU:
            return {...state, selectedMenu: action.payload}
        case SET_SLIDER:
            return {...state, slider: action.payload}
        case SET_SELECTED_FILTER:
            return {...state, selectedFilter: action.payload}
        case SET_DEVICE:
            return {...state, device: action.payload}
        case SET_AWARDS:
            return {...state, awards: action.payload}
        case SET_ISANIMATION:
            return {...state, isAnimation: action.payload}
        default:
            return state
    }
}

