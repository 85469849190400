import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import {
    setSelectedMenu, setSelectedFilter
} from '../../../actions'
import './index.css'


function AdministratorHeader(props) {

    const [selectedMenu, setSelectedMenu] = useState(props.selectedMenu)
    const [selectedFilter] = useState(props.selectedFilter)
    // const [displayNavbar, setDisplayNavbar] = useState('none')

    useEffect(()=> {
        if(props.selectedMenu !== selectedMenu) setSelectedMenu(props.selectedMenu)
    }, [selectedMenu,selectedFilter])

    // var prevScrollpos = window.pageYOffset;
    // window.onscroll = function() {
    // var currentScrollPos = window.pageYOffset;
    // if (prevScrollpos > currentScrollPos) {
    //     setDisplayNavbar('')
    // } else {
    //     setDisplayNavbar('none')
    // }
    // prevScrollpos = currentScrollPos;
    // }

    const handleActiveLink = event => {
        props.dispatchSetSelectedMenu(event.target.name)
        if (event.target.name === 'Projects') props.dispatchSetSelectedFilter('All')
    }

    const handleFilter = filter => {
        props.dispatchSetSelectedFilter(filter)
    }

    return (
        <>
            <nav className={"navbar navbar-expand-lg navbar-light bg-white"}>
                <Link className="navbar-brand indexSuperior"
                                to={`/`}
                                onClick={handleActiveLink}
                                name={"Slider"}>
                                ® Requena
                </Link>
                <div className="navbar-toggler" type="text" data-toggle="collapse"
                        data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <span>Menu</span>
                </div>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ml-auto indexSuperior">
                        <li className={(props.selectedMenu === 'Home') ? "nav-item bg-navbar-item" : "nav-item active"}>
                            <Link className={"nav-link"}
                                to={`/projects`}
                                onClick={handleActiveLink}
                                name={"Projects"}>Home</Link>
                        </li>
                        <li className={(props.selectedMenu === 'Projects') ? "nav-item bg-navbar-item" : "nav-item active"}>
                            <Link className={"nav-link"}
                                to={`/office`}
                                onClick={handleActiveLink}
                                name={"Office"}>Projects</Link>
                        </li>
                        <li className={(props.selectedMenu === 'Office') ? "nav-item bg-navbar-item" : "nav-item active"}>
                            <Link className={"nav-link disabled"}
                                to={`/shop`}
                                onClick={handleActiveLink}
                                name={"Shop"}>Home</Link>
                        </li>
                        <li className={(props.selectedMenu === 'Play') ? "nav-item bg-navbar-item" : "nav-item active"}>
                            <Link className={"nav-link"}
                                to={`/`}
                                onClick={handleActiveLink}
                                name={"Logout"}>Logout</Link>
                        </li>
                    </ul>
                </div>
            </nav>  
        </>
    )
}

const mapStateToProps = state => ({
    selectedMenu: state.selectedMenu,
    selectedFilter: state.selectedFilter
})

const mapDispatchToProps = dispatch => (
    {
        dispatchSetSelectedMenu: value => dispatch(setSelectedMenu(value)),
        dispatchSetSelectedFilter: value => dispatch(setSelectedFilter(value))
    }
)

export default connect(mapStateToProps, mapDispatchToProps)(AdministratorHeader)