import { createStore } from 'redux'
import { informationReducer } from '../../reducers/informationReducer'

const initialState = {
    device:'computer',
    isAnimation: true
}

export const store = createStore(
    informationReducer,
    initialState,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)